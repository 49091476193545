/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import LocaleLink from "~components/LocaleLink";
import Layout from "~components/Layout";
import SectionHeading from "~components/SectionHeading";
import SEO from "~components/SEO";
import Video from "~components/Video.jsx";

//
// todo : hooks rewrite for all components

const WorkPage = ({ data, location }) => {
  // ===========================================================================
  // context / ref / state

  const { locale, setLanguageWidgetColor, setNavColor } = useContext(
    AppContext
  );
  const { device } = useContext(DocumentContext);

  const [activeTag, setActiveTag] = useState(null);

  // ===========================================================================
  // variables

  const { allMarkdownRemark, markdownRemark } = data;
  const { frontmatter } = markdownRemark;

  const projects = [];
  const projectListRef = useRef();
  const tags = [];
  const tagsByProject = {};

  allMarkdownRemark.edges.forEach(({ node }) => {
    const { isPublished, language, showOnWork } = node.frontmatter;

    if (isPublished && showOnWork && locale.locale === language) {
      projects.push(node);
    }
  });

  projects.sort((a, b) => {
    const priorityA = a.frontmatter.priority;
    const priorityB = b.frontmatter.priority;

    if (priorityA < priorityB) {
      return -1;
    }

    if (priorityA > priorityB) {
      return 1;
    }

    return 0;
  });

  projects.forEach(project => {
    if (!project?.frontmatter?.isPublished || !project?.frontmatter?.tags) {
      return;
    }

    const projectTags = project.frontmatter.tags.split(`,`);

    if (!projectTags?.[0]) {
      return;
    }

    projectTags.forEach(rawTag => {
      const tag = rawTag.trim();

      if (!tags.includes(tag)) {
        tags.push(tag);
      }

      if (!tagsByProject?.[project?.frontmatter?.title]) {
        tagsByProject[project.frontmatter.title] = [];
      }

      tagsByProject[project.frontmatter.title].push(tag);
    });
  });

  // ===========================================================================
  // lifecycle

  useEffect(() => {
    setLanguageWidgetColor(`black`);
    setNavColor(`black`);
  }, []);

  useEffect(() => {
    if (
      !activeTag ||
      typeof window === `undefined` ||
      !projectListRef?.current
    ) {
      return;
    }

    const top = projectListRef.current.offsetTop - 64;

    window.scrollTo({
      top,
      behavior: `smooth`
    });
  }, [activeTag]);

  // ===========================================================================
  // render

  let projectPaddingAlignDefault = true;
  let projectIndex = -1;

  tags.sort((a, b) => {
    return a < b ? -1 : 1;
  });

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="work-page w-full relative" location={location}>
        <section className="w-full relative xs:mt-12 mb-24 xs:mb-12 text-black">
          <div className="animation-appear-right animation-delay-1">
            <SectionHeading text="Case Studies" />
          </div>

          <header className="grid">
            <h2 className="animation-appear-down animation-delay-3 grid-end-13 grid-start-4 md:grid-end-20 sm:grid-end-22 sm:grid-start-2 mt-8 f1">
              {frontmatter?.heading}
            </h2>

            {(device === `desktop` && (
              <ul className="animation-appear-right animation-delay-2 grid-end-3 grid-start-21 relative mt-8">
                <li className="relative">
                  <button
                    type="button"
                    className="relative flex py-1"
                    onClick={() => setActiveTag(null)}
                  >
                    <span
                      className={`${
                        activeTag === null ? `` : `opacity-0`
                      } b2 mr-1`}
                    >
                      •
                    </span>

                    <span
                      className={`${
                        activeTag === null ? `opacity-25` : ``
                      } transition-opacity block b2`}
                    >
                      All
                    </span>
                  </button>
                </li>

                {tags?.[0] &&
                  tags.map(tag => {
                    const key = tag;

                    return (
                      <li key={key}>
                        <button
                          type="button"
                          className="relative flex py-1 text-left"
                          onClick={() => setActiveTag(tag)}
                        >
                          <span
                            className={`${
                              activeTag === tag ? `` : `opacity-0`
                            } block mr-1 pointer-events-none b2`}
                          >
                            •
                          </span>

                          <span
                            className={`${
                              tag === activeTag ? `opacity-25` : ``
                            } transition-opacity block pointer-events-none b2`}
                          >
                            {tag}
                          </span>
                        </button>
                      </li>
                    );
                  })}
              </ul>
            )) || (
              <ul className="grid-end-22 grid-start-2 relative flex overflow-x-auto mt-8 py-4">
                <li className="work-page__category relative">
                  <button
                    type="button"
                    className="work-page__category__button flex p-1 xs:px-4"
                    onClick={() => setActiveTag(null)}
                  >
                    <span
                      className={`${
                        activeTag === null ? `` : `opacity-0`
                      } b2 mr-1`}
                    >
                      •
                    </span>

                    <span
                      className={`${
                        activeTag !== null ? `opacity-25` : ``
                      } transition-opacity block b2`}
                    >
                      All
                    </span>
                  </button>
                </li>

                {tags.map(tag => (
                  <li key={tag} className="work-page__category relative">
                    <button
                      type="button"
                      className="work-page__category__button flex p-1 xs:px-4"
                      onClick={() => setActiveTag(tag)}
                    >
                      <span
                        className={`${
                          activeTag === tag ? `` : `opacity-0`
                        } b2 mr-1`}
                      >
                        •
                      </span>

                      <span
                        className={`${
                          activeTag === tag ? `opacity-25` : ``
                        } transition-opacity block b2`}
                      >
                        {tag}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </header>
        </section>

        <section
          ref={projectListRef}
          className="work-page__projects w-full relative block"
        >
          {projects?.[0] && (
            <ul className="grid">
              {projects.map((project, index) => {
                const key = project?.fields?.slug;
                const rawProjectTags = project?.frontmatter?.tags?.split(`,`);
                const projectTags = [];

                rawProjectTags.forEach(rawTag => {
                  projectTags.push(rawTag.trim());
                });

                if (
                  project?.frontmatter?.isPublished !== true ||
                  (activeTag && !projectTags?.includes(activeTag?.trim())) ||
                  !project?.frontmatter?.title ||
                  !project?.frontmatter?.backgroundImage?.childImageSharp?.fluid
                ) {
                  return <React.Fragment key={key} />;
                }

                projectIndex += 1;

                const isLarge = projectIndex % 5 === 0;

                let innerJSX;
                let outerClassName;
                let { slug } = project?.fields;

                if (slug?.includes(`.`)) {
                  [slug] = project.fields.slug.split(`.`);
                }

                if (isLarge || device !== `desktop`) {
                  projectPaddingAlignDefault = !projectPaddingAlignDefault;
                  outerClassName = `grid-end-24`;

                  innerJSX = (
                    <article className="relative">
                      <figure className="w-full relative">
                        {device === `desktop` && (
                          <section className="work-page__project__overlay transition-opacity w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center justify-center pointer-events-none">
                            <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center pointer-events-none opacity-25 bg-black"></div>

                            <h3 className="work-page__project__overlay__text relative z-20 f4 text-white">
                              VIEW PROJECT →
                            </h3>
                          </section>
                        )}

                        {(project?.frontmatter?.workBackgroundVideo && (
                          <Video
                            src={project.frontmatter.workBackgroundVideo}
                          />
                        )) || (
                          <>
                            {project?.frontmatter?.backgroundImage
                              ?.childImageSharp && (
                              <Img
                                className="w-full relative block"
                                fluid={
                                  project.frontmatter.backgroundImage
                                    .childImageSharp.fluid
                                }
                                alt={project.frontmatter.backgroundImageAlt}
                              />
                            )}
                          </>
                        )}
                      </figure>

                      <header className="relative mt-8 xs:mt-4">
                        <h3
                          className={`${
                            device === `desktop` ? `b1` : `b2`
                          } ml-12 xs:ml-4 feijoa-ot`}
                        >
                          <span className="mr-2">—</span>
                          <span>{project.frontmatter.title}</span>
                        </h3>

                        <h2
                          className={`mt-2 xs:mt-1 ${
                            device === `desktop` ? `f2` : `f3`
                          }`}
                        >
                          {project.frontmatter.tagline}
                        </h2>
                      </header>
                    </article>
                  );
                } else {
                  let paddingClassName;

                  if (projectPaddingAlignDefault) {
                    paddingClassName = `${
                      projectIndex % 2 === 0 ? `pr-3` : `pl-3`
                    }`;
                  } else {
                    paddingClassName = `${
                      projectIndex % 2 === 0 ? `pl-3` : `pr-3`
                    }`;
                  }

                  outerClassName = `grid-end-12 xs:grid-end-24 ${paddingClassName} xs:pr-0 xs:pl-0`;

                  innerJSX = (
                    <article className="relative">
                      <div
                        className="w-full relative flex items-center justify-center overflow-hidden"
                        style={{
                          height: `26vw`
                        }}
                      >
                        {device === `desktop` && (
                          <section className="work-page__project__overlay transition-opacity w-full h-full absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center justify-center pointer-events-none">
                            <div className="w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center pointer-events-none opacity-25 bg-black"></div>

                            <h3 className="work-page__project__overlay__text relative z-20 f4 text-white">
                              VIEW PROJECT →
                            </h3>
                          </section>
                        )}

                        {(project?.frontmatter?.workBackgroundVideo && (
                          <Video
                            className="w-full h-full relative block object-cover"
                            src={project.frontmatter.workBackgroundVideo}
                          />
                        )) || (
                          <>
                            {project?.frontmatter?.backgroundImage
                              ?.childImageSharp && (
                              <Img
                                className="w-full h-full relative block object-cover"
                                fluid={
                                  project.frontmatter.backgroundImage
                                    .childImageSharp.fluid
                                }
                                alt={project.frontmatter.backgroundImageAlt}
                              />
                            )}
                          </>
                        )}
                      </div>

                      <header className="relative flex items-center justify-between mt-2">
                        <h2 className="b1 untitled-sans">
                          {project.frontmatter.tagline}
                        </h2>

                        <h3 className="b1 feijoa-ot">
                          <span className="mr-2">—</span>
                          <span>{project.frontmatter.title}</span>
                        </h3>
                      </header>
                    </article>
                  );
                }

                //

                return (
                  <li
                    key={key}
                    className={`${outerClassName} work-page__project animation-appear relative mb-20 xs:mb-12`}
                    style={{ animationDelay: `${projectIndex * 50}ms` }}
                  >
                    <LocaleLink to={slug}>{innerJSX}</LocaleLink>
                  </li>
                );
              })}
            </ul>
          )}
        </section>

        <Footer />
      </Layout>
    </>
  );
};

export default WorkPage;

export const workPageQuery = graphql`
  query WorkPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading

        seoDescription
        seoKeywords
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }

          frontmatter {
            title
            language
            isPublished
            showOnWork
            priority
            tags
            tagline

            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            backgroundImageAlt
            backgroundImageFontColor

            workBackgroundVideo
          }
        }
      }
    }
  }
`;
